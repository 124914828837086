import { getStudent, getStudentDetails, hasPendingPayment, hasPendingPIC } from 'api';
import { listMyStudents } from 'api/coachDashboard';
import { ApiResponse } from 'apisauce';
import { types, flow, Instance, cast } from 'mobx-state-tree';
import User, { Student, UserAvatar, UserImage } from './User';


const SchoolModel = types.model("SchoolModel", {
  title: types.maybeNull(types.string),
  logo: types.maybeNull(types.string)
});

const StudentModel = types.model("StudentModel", {
  name: types.string,
  school:  SchoolModel,
  gender: types.string,
  avatar: UserAvatar,
  image: UserImage,
  grade: types.string
});

export const StudentStore = types
  .model('StudentStore', {
    students: types.array(User),
    student: types.maybe(Student),
    studentModel: types.maybe(StudentModel),
    loading: false,
  })
  .actions((self) => ({
    listMyStudents: flow(function* ({ q, batchName }: { q: string; batchName?: string }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield listMyStudents({ q, batchName });
        if (response.problem) {
          return;
        }
        self.students = response.data.results;
      } finally {
        self.loading = false;
      }
    }),
    getStudent: flow(function* (username: string) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield getStudent(username);
        if (response.problem) {
          return;
        }
        self.student = response.data;
      } finally {
        self.loading = false;
      }
    }),
    getPICBookingLink: flow(function* (username: string) {
      try {
        const response: ApiResponse<any> = yield hasPendingPIC(username);
        if (response.problem) {
          return;
        }
        return response.data.booking_link;
      } finally {
      }
    }),
    getPartialPaymentLink: flow(function* (username: string) {
      try {
        const response: ApiResponse<any> = yield hasPendingPayment(username);
        if (response.problem) return;
        if (response.data.link) return response.data.link;
      } finally {
      }
    }),
    fetchStudentDetails: flow(function* (username: string) {
      try {
        const response: ApiResponse<any> = yield getStudentDetails(username);
        if (response.problem) return;
        self.studentModel = cast(response.data);
      } finally {
      }
    }),
  }));

export interface IStudentStore extends Instance<typeof StudentStore> {}
