const prodConfig = {
  REACTION_TIMER: 10,
  SENTRY_DSN: 'https://7ef69da8ec78f618e9f1ccc6e81ec224@o4508007518109696.ingest.us.sentry.io/4508838318964736',
  BASE_URL: `https://${window.location.host}`,
  SOCKET_BASE_URL: `wss://${window.location.host}`,
  API_BASE_URL: `https://${window.location.host}/api/`,
  GOOGLE_OAUTH_CLIENT_ID: '581542018185-unn4n0hji123nke7gq0rtrjdrgbtbocv.apps.googleusercontent.com',
  GTM_ID: 'G-DPX5TQCP9D',
  MIXPANEL_TOKEN: '2779283b3021a3ae56509faad99ea2fd',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyDGUVoJs_34o_Tt38GHpjathrhU5A33X-8',
    authDomain: 'stayq-fb.firebaseapp.com',
    databaseURL: 'https://stayq-fb-default-rtdb.firebaseio.com',
    projectId: 'stayq-fb',
    storageBucket: 'stayq-fb.appspot.co',
    messagingSenderId: '104423127293',
    appId: '1:104423127293:web:9f0c62487687cbff472694',
    measurementId: 'G-LXWBD6VBDS',
  },
};

export default prodConfig;
